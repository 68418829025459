import React from 'react';
import './prueba.css'

const ImgOfSection = ({ img, inv = false, children = null, title = null, visualText = true }) => {

  const style = {
    backgroundImage: `url(${(img)})`,
    backgroundPosition: "bottom right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minHeight: "300px"
  };

  const getTexts = (className = "") => {
    return (
      <React.Fragment>
        <div style={{ fontSize: "40px", lineHeight: "45px", marginTop: "80px" }}
             className={"row justify-content-center ltt-spacing-5 ffMontserrat " + className}>
          {title !== null && title}
        </div>
        <div className={"row justify-content-center ffMontserrat " + className}>
          CAN COLETO
        </div>
        <div className={"row " + className}>
          <div className="col-12">
            {children !== null && children}
          </div>
        </div>
      </React.Fragment>
    )
  };

  return (
    <div style={style} className="row h-100">
      {!inv && <div className="col-12 col-lg-6">
        {visualText && <React.Fragment>
          {getTexts("d-lg-none")}
        </React.Fragment>}
      </div>}
      <div className={"d-none d-lg-block col-6 " + (inv ? "bc-inv" : "bc")}>
        {visualText && <React.Fragment>
          {getTexts("d-none d-lg-flex")}
        </React.Fragment>}
      </div>
      {inv && <div className="col-12 col-lg-6">
        {visualText && <React.Fragment>
          {getTexts("d-lg-none")}
        </React.Fragment>}
      </div>}
    </div>
  );
};

export default ImgOfSection;
