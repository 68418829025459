import React, { Component } from 'react';
import { localeConsControl } from "./localeConsControl";
import en from './../../locale/en'
import es from './../../locale/es'
import de from './../../locale/de'
import ru from './../../locale/ru'

function traductorControl(WhatComponentNeedsTraductorControl) {
  const hoc = class extends Component {
    langs = { "en": en, "es": es, "de": de, "ru": ru };

    t = (locale, key) => {
      if (this.langs[locale][key] === undefined) {
        return key
      }
      return this.langs[locale][key]
    };

    render() {
      const { locale } = this.props;
      const t = this.t;
      return (
        <WhatComponentNeedsTraductorControl
          t={function (key) {
            return t(locale, key);
          }}
          langs={this.langs}
          {...this.props}/>
      );
    }
  };

  hoc.displayName = (WhatComponentNeedsTraductorControl.displayName || WhatComponentNeedsTraductorControl.name);
  return localeConsControl(hoc);
}

export { traductorControl };
