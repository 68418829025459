import React, { PureComponent } from 'react';
import { Switch, Route, HashRouter, Redirect } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { localeProvControl } from "./components/wrappers/localeProvControl";
import NavBar from "./components/pages/navbar/NavBar";
import Footer from "./components/pages/footer/Footer";
import Home from './components/pages/Home'
import PhotoGallery from './components/pages/PhotoGallery'
import Contact from './components/pages/Contact'
import './app.css'
import 'react-toastify/dist/ReactToastify.css';
import Wines from "./components/pages/Wines";


class App extends PureComponent {
  render() {
    return (
      <HashRouter>
        <NavBar/>
        <ToastContainer/>
        <Switch>
          <Route exact path="/home" component={Home}/>
          <Route exact path="/wines" component={Wines}/>
          <Route exact path="/gallery" component={PhotoGallery}/>
          <Route exact path="/contact" component={Contact}/>
          <Redirect to="/home"/>
        </Switch>
        <Footer/>
      </HashRouter>
    );
  }
}

export default localeProvControl(App);
