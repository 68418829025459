import React from 'react';
import sectionImg from "../../../../media/wallpaper/section2.jpg";
import ImgOfSection from "./shared/ImgOfSection";

const Section2 = ({ t }) => {

  return (
    <section className="container-fluid">
      <div style={{ minHeight: "500px" }} className="row">
        <div style={{ zIndex: 200 }} className="col-12 position-absolute justify-content-start ffMontserrat py-4">
          COLETO
        </div>
        <div className="col-12 col-lg-8">
          <ImgOfSection img={sectionImg} title={t("HISTORY")}/>
        </div>
        <div className="col-12 col-lg-4 bg-primary">
          <div className="row h-100">
            <div style={{ textAlign: "justify" }} className="col-12 ffMontserrat my-5 align-self-center">
              <div className="row px-5 my-3">
                {t("Since 2000 we have started our vine production following the organic farming system.")}
                {" "}
                {t("We started planting a small plot that of about 2000m2. The intention was to continue making wine from home.")}
                <p>
                  {t("Homemade wine was a very old Mallorcan tradition that consisted of making wine for your own consumption. They gathered a few families and, working together, they made the wine that was later distributed.")}
                </p>
                <br/>
                <p>
                  {t("Times were changing, the vineyards were getting old, people were looking for easy money and the vine had a lot of work. Then the vines were neglecting and snatching. We are talking about the 70s.")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div style={{ zIndex: 200 }}
             className="col-12 d-flex align-self-end position-absolute justify-content-end ffMontserrat py-4">
          COLETO
        </div>
      </div>
    </section>
  );
};

export default Section2;
