

const photos = [
  {
    src: require("../../../media/photos/photo1.jpg"),
    width: 16,
    height: 9
  },
  {
    src: require("../../../media/photos/photo2.jpg"),
    width: 10,
    height: 8
  },
  {
    src: require("../../../media/photos/photo3.jpg"),
    width: 3,
    height: 2
  },
  {
    src: require("../../../media/photos/photo4.jpg"),
    width: 16,
    height: 9
  },
  {
    src: require("../../../media/photos/photo5.jpg"),
    width: 3,
    height: 2
  },
  {
    src: require("../../../media/photos/photo6.jpg"),
    width: 4,
    height: 3
  },
  {
    src: require("../../../media/photos/photo7.jpg"),
    width: 7,
    height: 5
  },
  {
    src: require("../../../media/photos/photo8.jpg"),
    width: 2,
    height: 2
  },
  {
    src: require("../../../media/photos/photo9.jpg"),
    width: 16,
    height: 9
  },
  {
    src: require("../../../media/photos/photo10.jpg"),
    width: 4,
    height: 3
  },
  {
    src: require("../../../media/photos/photo11.jpg"),
    width: 3,
    height: 3
  },
  {
    src: require("../../../media/photos/photo12.jpg"),
    width: 16,
    height: 9
  },
  {
    src: require("../../../media/photos/photo13.jpg"),
    width: 3,
    height: 2
  },
  {
    src: require("../../../media/photos/photo14.jpg"),
    width: 16,
    height: 9
  },
  {
    src: require("../../../media/photos/photo15.jpg"),
    width: 3,
    height: 2
  },
  {
    src: require("../../../media/photos/photo16.jpg"),
    width: 16,
    height: 9
  },
];


export default photos;
