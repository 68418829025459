import React, { PureComponent } from 'react';
import { traductorControl } from "../wrappers/traductorControl";
import { modalProvControl } from "../wrappers/modalProvControl";
import Section2 from "./home/sections/Section2";
import Section1 from "./home/sections/Section1";
// import AppDisponibility from "../../components/shared/app/AppDisponibility";
import './home/home.css'
import logo from "../../media/logos/logo.png"
import Banner from "../shared/banner/Banner";


class Home extends PureComponent {

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        {/*<AppDisponibility/>*/}
        <Banner size="100vh" arrows>
          <div className="container-fluid h-100">
            <div className="row justify-content-center align-items-center h-100 text-white ffMontserrat" style={{fontSize: "50px"}}>
              <img width="300px" style={{maxWidth: "90vh"}} src={logo} alt=""/>
            </div>
          </div>
        </Banner>
        <Section2 t={t}/>
        <Section1 t={t}/>
      </React.Fragment>
    );
  }
}

export default traductorControl(modalProvControl(Home));
