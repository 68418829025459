import React, { Component } from 'react';
import { traductorControl } from "../../../wrappers/traductorControl";
import posed from 'react-pose';
import { Link } from "react-router-dom";

const MoveDiv = posed.div({
  closed: {
    width: 0
  },
  open: {
    width: '100%'
  }
});

class SectionToSelect extends Component {

  state = {
    select: false
  };

  yasSelect = () => {
    this.setState({ select: true })
  };

  noneSelect = () => {
    this.setState({ select: false })
  };

  static defaultProps = {
    text: null,
    to: null,
    hover: true
  };

  render() {
    const { to, text, children, t, onClick, hover } = this.props;
    const { select } = this.state;
    return (
      <React.Fragment>
        <div style={{ cursor: "pointer" }}
             onMouseLeave={this.noneSelect}
             onMouseEnter={this.yasSelect}
             className="d-flex flex-column align-items-center justify-content-center mx-2 my-3 my-lg-4">
          {to !== null ?
            <Link to={to} onClick={onClick} className="d-flex text-white">{text !== null ? t(text) : children}</Link>
            :
            <div className="d-flex text-white">
              {text !== null ? t(text) : children}
            </div>
          }
          {hover ?
            <MoveDiv pose={select ? 'open' : 'closed'} className="border-bottom border-secondary">

            </MoveDiv>
            :
            <div style={{ marginBottom: "1px" }}>

            </div>
          }
        </div>
      </React.Fragment>
    );
  }
}

export default traductorControl(SectionToSelect);
