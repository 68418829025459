import React from 'react';
import sectionImg from "../../../../media/wallpaper/section1.jpg";
import ImgOfSection from "./shared/ImgOfSection";

const Section1 = ({ t }) => {

  return (
    <section className="container-fluid">
      <div style={{ minHeight: "500px" }} className="row">
        <div style={{ zIndex: 200 }} className="col-12 position-absolute justify-content-start ffMontserrat py-4">
          COLETO
        </div>
        <div className="col-12 col-lg-4 bg-primary order-1 order-lg-0">
          <div className="row h-100">
            <div style={{ textAlign: "justify" }} className="col-12 ffMontserrat my-5 align-self-center">
              <div className="row px-5 my-3">
                {t("When we joined the European Economic Community, vineyard starts were subsidized. Most people tore their vine and we saw that it was increasingly difficult to find grapes to buy.\nThis explains why we started growing vineyards to be able to follow the family tradition. We decided to sow Monastrell since we wanted a wine of fast consumption (young) and of good production.")}
                <br/>
                <br/>
                <p>
                  {t("We will select the type of vine that gave good performance in Mallorca and that allowed, in a short time, to be able to produce quality wines.")}
                </p>
                <br/>
                <p>
                  {t("Our current plantation is 2.5 hectares and all wines are made from the grapes of our vineyards.")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8 bg-dark order-0 order-lg-1">
          <ImgOfSection img={sectionImg} className="bg-black-60" inv title={t("TRADITION")}/>
        </div>
      </div>
    </section>
  );
};

export default Section1;
