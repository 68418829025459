import React, { PureComponent } from 'react';
import { traductorControl } from "../wrappers/traductorControl";
import { modalProvControl } from "../wrappers/modalProvControl";
import './home/home.css'
import BannerContainer from "../shared/banner/BannerContainer";
import SectionGeneric from "./wines/SectionGeneric";
import blancDeNegres from "./../../media/wines/blancDeNegres.png"
import w1713 from "./../../media/wines/1713.png"
import eixut from "./../../media/wines/camiDeSonEixut.png"
import summum from "./../../media/wines/summum.png"
import nVirat from "./../../media/wines/negreVirat.png"
import nSuau from "./../../media/wines/negreSuau.png"
import nMediterrani from "./../../media/wines/negreMediterrani.png"
import llBlanques from "./../../media/wines/llagrimesBlanques.png"
import SectionGenericInv from "./wines/SectionGenericInv";


class Wines extends PureComponent {

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <BannerContainer size="50vh"/>
        <SectionGeneric t={t} img={blancDeNegres} title={"BLANC DE NEGRES"}>
          <p>
            BLANC DE NEGRES
          </p>
          <br/>
          <br/>
          <p>
            {t("Pale yellow wine with greenish reflection. Intense aroma where we find very ripe fruits such as melon and pear, accompanied by balsamic touches of fennel and Luisa herb. In the mouth it presents a pleasant game of acid and sweet flavors, fresh and smooth. It presents a wide and clean mouth end of great persistence and harmony.")}
          </p>
          <p className="mt-3">
            - {t("Varieties:")} Syrah (40%), Merlot (40%) y Cabernet (20%)
          </p>
          <p>
            - {t("Graduation:")} 13,5%
          </p>
          <p>
            - {t("Elaboration: The grapes were selected from each vine at the end of August by lightening the vineyard. Then the whole grape was pressed directly and the usual technique was used to make white wines without using oak barrels.")}
          </p>
          <p>
            - {t("Ecological cultivation.")}
          </p>
        </SectionGeneric>
        <SectionGenericInv t={t} img={w1713} title={"1713"}>
          <p>
            1713
          </p>
          <br/>
          <br/>
          <p>
            {t("Not all are commemorations and institutional memories in the vineyard of Father Serra. The harvest in honor of Miguel José Serra Ferrer, evangelist of California and founder of cities like Los Angeles or San Francisco, also understands wines. Can Coleto Wines brings to the market this 2013, a series of bottles in honor of the 300th anniversary of the birth of Blessed Petrer. The name, like the date, coincide: 1713.")}
          </p>
          <br/>
          <p>
            {t("'This wine is intended as a small tribute to the work done by Father Serra, who helped build what is now the state of California, while putting into practice his motto Always Forward, never behind.' - Miquel Jaume")}
          </p>
          <br/>
          <p>
            {t("The limited edition bottles (with the Pla i Llevant designation of origin) will have an image of the Franciscan religious who will accompany the labels themselves attached to the bottle.")}
          </p>
        </SectionGenericInv>
        <SectionGeneric t={t} img={eixut} title={"CAMÍ DE SON EIXUT"}>
          <p>
            CAMÌ DE SON EIXUT
          </p>
          <br/>
          <br/>
          <p>
            {t("Red wine very well covered, with purple tones on deep garnet. It has aromas of red fruit mixed with balsamic and fennel tones. In the mouth it is very wide with sweet sensations and well structured tannins that predict a good evolution. The end is long, highlighting a spaced feeling that gives it a certain personality.")}
          </p>
          <p className="mt-3">
            - {t("Varieties:")} Merlot
          </p>
          <p>
            - {t("Aging: twelve months inside French and American oak barrels, and rest inside the cellar")}
          </p>
          <p>
            - {t("Ecological cultivation.")}
          </p>
          <p>
            , {t("CE-ES BA control system")}
          </p>
        </SectionGeneric>
        <SectionGenericInv t={t} img={summum} title={"SUMMUM"}>
          <p>
            SUMMUM
          </p>
          <br/>
          <br/>
          <p>
            {t("Dark garnet red wine with great presence.\nAromas of extraordinary intensity reminiscent of blackberries, redcurrant and ripe cherries in a first blow, then turning to sweeter aromas reminiscent of jams and a light touch of English bonbon. Balsamic touches of lemongrass, mint and spices such as vanilla and teme (thyme) are also appreciated.")}
          </p>
          <br/>
          <p>
            {t("In the mouth it is vigorous and mature. Very high tannicity but of good quality. Aroma of very powerful mouth that ends at an extraordinarily long end.")}
          </p>
          <p className="mt-3">
            - {t("Varieties:")} Cabernet Sauvignon.
          </p>
          <p>
            - {t("Aging: eighteen months inside French and American oak barrels, and rest inside the cellar")}
          </p>
          <p>
            - {t("Ecological cultivation.")}
          </p>
          <p>
            , {t("CE-ES BA control system")}
          </p>
        </SectionGenericInv>
        <SectionGeneric t={t} img={nVirat} title={"NEGRE VIRAT"}>
          <p>
            NEGRE VIRAT
          </p>
          <br/>
          <br/>
          <p>
            {t("This wine has an unusual color intensity. It has deep aromas and great nobility: wild raspberry, ripe plum, burnt wood and licorice. The palate is very wide and robust, with very present tannins but not too aggressive. It is accompanied by touches of cedar and vanilla. It is a wine of unusual aromatic persistence.")}
          </p>
          <p className="mt-3">
            - {t("Varieties:")} Cabernet Sauvignon.
          </p>
          <p>
            - {t("Aging: twelve months inside French and American oak barrels and rest inside the cellar.")}
          </p>
          <p>
            - {t("Ecological cultivation.")}
          </p>
          <p>
            , {t("CE-ES BA control system")}
          </p>
        </SectionGeneric>
        <SectionGenericInv t={t} img={nSuau} title={"NEGRE SUAU"}>
          <p>
            NEGRE SUAU
          </p>
          <br/>
          <br/>
          <p>
            {t("Ruby red wine moderately covered with purple hues. Presents aromas of tree fruits such as apricot and peach on a jam background. The palate is soft, without edges, with a very balanced acidity but with fresh sensations. The tannins are structured and domesticated, so they are integrated into a set of great harmony.")}
          </p>
          <p className="mt-3">
            - {t("Varieties:")} Monastrell ( > 85%), Merlot y Cabernet Sauvignon.
          </p>
          <p>
            - {t("Aging: it is a young wine of rapid consumption, maximum three years.")}
          </p>
          <p>
            - {t("Ecological cultivation.")}
          </p>
          <p>
            , {t("CE-ES BA control system")}
          </p>
        </SectionGenericInv>
        <SectionGeneric t={t} img={nMediterrani} title={"NEGRE MEDITERRANI"}>
          <p>
            NEGRE MEDITERRANI
          </p>
          <br/>
          <br/>
          <p>
            {t("Cherry red wine. It presents aromas of sweet fruits like pear and peach integrated in a bottom of bathed grass and toasted bread. In the mouth it is very kind, with silky and velvety tannins. Slight touches of butter and heated milk are noted. The acidity is very well integrated.")}
          </p>
          <p className="mt-3">
            - {t("Varieties:")} Syrah.
          </p>
          <p>
            - {t("Aging: twelve months inside French and American oak barrels and rest inside the cellar.")}
          </p>
          <p>
            - {t("Ecological cultivation.")}
          </p>
          <p>
            , {t("CE-ES BA control system")}
          </p>
        </SectionGeneric>
        <SectionGenericInv t={t} img={llBlanques} title={"LLÀGRIMES BLANQUES"}>
          <p>
            LLÀGRIMES BLANQUES
          </p>
          <br/>
          <br/>
          <p>
            {t("Golden yellow wine with greenish nuances. It has wide aromas, where we find the exotic fruits - mainly pineapple and plantain - on a background of butter and soft toasted and citrus touches. The palate is fresh and velvety, highlighting a remarkable consistency. A pleasant game of acid and sweet sensations emerges. The finish is very noble, with spicy tones but without losing freshness.")}
          </p>
          <p className="mt-3">
            - {t("Varieties:")} Chardonnay.
          </p>
          <p>
            - {t("Aging: Completion of fermentation inside new French Allier oak edges; He has remained in the boots for a minimum of three months, after resting inside the cellar.")}
          </p>
          <p>
            - {t("Ecological cultivation.")}
          </p>
          <p>
            , {t("CE-ES BA control system")}
          </p>
        </SectionGenericInv>
      </React.Fragment>
    );
  }
}

export default traductorControl(modalProvControl(Wines));
