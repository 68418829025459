import React, { PureComponent } from 'react';
import { traductorControl } from "../wrappers/traductorControl";
import MyInput from "../shared/form/MyInput";
import MySubmit from "../shared/form/MySubmit";
import { toast } from 'react-toastify';
import { sendEmail } from "../../mail/sendEmail";
import MyTextArea from "../shared/form/MyTextArea";

class Contact extends PureComponent {

  state = {
    name: "",
    surname: "",
    phone: "",
    email: "",
    comment: ""
  };
  errors = [];

  componentWillMount() {
    window.scrollTo(0, 0)
  }

  captureErrors = (where, isCorrect) => {
    const indice = this.errors.indexOf(where);
    const existsIndice = indice >= 0;
    isCorrect ? (existsIndice && this.errors.splice(indice, 1)) : (!existsIndice && this.errors.push(where));
  };

  changeBooking = (event) => this.setState({
    [event.target.name]: (event.target.value)
  });

  submit = (event) => {
    event.preventDefault();
    const { t } = this.props;
    const { name, surname, phone, email, comment } = this.state;
    if (this.errors.length < 1) {
      sendEmail({
        title: "CAN COLETO WEB - CONTACTO",
        html: `<h4>Comentario de parte de: ${name + " " + surname}</h4><p>Nombre: ${name + " " + surname}</p><p>Teléfono: ${phone}</p><p>Correo: ${email}</p><p>Comentario: ${comment}</p>`,
      })
      .then(() => {
        toast(name + " " + surname + ", " + t("thanks by contact with us. You will get an answer as quickly as possible."), {
          className: "bg-success ffMontserrat text-white",
          autoClose: false
        });
      });
    } else {
      toast(t("Please, enter correctly all the requested sections."), { className: "bg-danger ffMontserrat text-white" });
    }
  };

  render() {
    const { name, surname, phone, email, comment } = this.state;
    const { t } = this.props;
    return (
      <form onSubmit={this.submit}
            className="container-fluid text-light ffMontserrat">
        <div className="row min-vh-100 justify-content-center align-items-center"
             style={{ paddingTop: "70px", paddingBottom: "70px" }}>
          <div className="col-11 col-sm-10 col-md-9 col-lg-8 col-xl-7">
            <div className="row justify-content-center mb-5 ltt-spacing-5"
                 style={{ fontSize: "45px", lineHeight: "45px" }}>
              {t("CONTACT")}
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <MyInput title="NAME" type="text" name="name" value={name}
                         onChange={this.changeBooking} captureErrors={this.captureErrors} notEmpty={true}
                         className="mr-md-2"/>
              </div>
              <div className="col-12 col-md-6">
                <MyInput title="SURNAME" type="text" name="surname" value={surname}
                         onChange={this.changeBooking} captureErrors={this.captureErrors} notEmpty={true}
                         className="ml-md-2"/>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <MyInput title="PHONE" type="number" name="phone" value={phone}
                         onChange={this.changeBooking} captureErrors={this.captureErrors} notEmpty={true} minChar={9}
                         className="mr-md-2"/>
              </div>
              <div className="col-12 col-md-6">
                <MyInput title="EMAIL" type="email" name="email" value={email}
                         onChange={this.changeBooking} captureErrors={this.captureErrors} notEmpty={true} contain="@"
                         className="ml-md-2"/>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <MyTextArea title="COMMENT" type="text" name="comment" value={comment}
                            onChange={this.changeBooking} captureErrors={this.captureErrors} className="mr-md-2"/>
              </div>
              <div className="col-12 col-md-6">
                <div className="row h-100 align-items-end py-3 ml-md-2">
                  <MySubmit style={{ height: "48px", marginTop: "6px" }} text="SEND" className="ffMontserrat"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default traductorControl(Contact);
