import React, { Component } from 'react';
import img from '../../../media/wallpaper/wall1-long.jpg'

class Banner extends Component {

  static defaultProps = {
    img: img,
    size: "70vh",
    back: false
  };

  render() {

    const { img, size } = this.props;

    const style = {
      backgroundImage: `url(${(img)})`,
      backgroundPosition: "center",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      height: size
    };

    return (
      <div style={style} className="container-fluid overflow-hidden">
        {this.props.children}
      </div>
    );
  }
}

export default Banner;
