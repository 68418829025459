import React from 'react';
import ImgOfSection from "./ImgOfSection";

const SectionGenericInv = ({ t, img, children, title }) => {

  return (
    <section className="container-fluid">
      <div style={{ minHeight: "500px" }} className="row">
        <div style={{ zIndex: 200 }} className="col-12 position-absolute justify-content-start ffMontserrat py-4">
          COLETO
        </div>
        <div className="col-12 col-lg-4 bg-primary order-1 order-lg-0">
          <div className="row h-100">
            <div style={{ textAlign: "justify" }} className="col-12 ffMontserrat my-5 align-self-center">
              <div className="row px-5 my-3">
                {children}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8 bg-dark order-0 order-lg-1">
          <ImgOfSection img={img} className="bg-black-60" inv title={t(title)} visualText={false}/>
        </div>
      </div>
    </section>
  );
};

export default SectionGenericInv;
