import React from 'react';
import { traductorControl } from "../../wrappers/traductorControl";

const Footer = ({ t }) => {
  return (
    <div className="container-fluid bg-secondary">
      <div className="row ffMontserrat px-3 align-items-center justify-content-between">
        <div className="col-auto">
          <div className="row my-2 align-items-center text-uppercase">
            {new Date().getFullYear()} VINS CAN COLETO | {t("All rights reserved")}
          </div>
        </div>
        <div className="col">
          <div className="d-flex justify-content-end my-4">
            Tlf: 658 879 488
          </div>
        </div>
      </div>
    </div>
  );
};

export default traductorControl(Footer);
