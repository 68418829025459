import React, { Component } from 'react';
import posed from 'react-pose';
import { Link } from "react-router-dom";
import { localeConsControl } from "../../wrappers/localeConsControl";
import { traductorControl } from "../../wrappers/traductorControl";
import menu from '../../../media/icons/menu.svg'
import logo from "./../../../media/logos/logo.png"
import SectionToSelect from './sectionToSelect/SectionToSelect'
import './navBar.css'

const MoveDiv = posed.div({
  closed: { height: 0 },
  open: { height: 'auto' }
});

class NavBar extends Component {

  state = {
    isVisibleMobileMenu: false
  };

  componentDidMount() {
    window.onscroll = () => {
      this.closeMenu()
    }
  }

  closeMenu = () => {
    this.setState({ isVisibleMobileMenu: false })
  };

  toggleMenu = () => {
    this.setState({ isVisibleMobileMenu: !this.state.isVisibleMobileMenu })
  };


  render() {
    const { isVisibleMobileMenu } = this.state;
    const { locale, changeLocale, langs } = this.props;
    return (
      <React.Fragment>
        <div style={{ zIndex: 2000 }} onMouseLeave={this.closeMenu}
             className="container-fluid fixed-top ffMontserrat bg-primary-70">
          <div className="row d-none d-lg-flex justify-content-end align-items-center px-5 mx-4"
               style={{ opacity: 1, top: 0 }}>
            <Link style={{ fontSize: "25px" }} to="/" className="mr-auto text-white">
              <img height="40px" className="mr-auto" src={logo} alt=""/>
            </Link>
            <SectionToSelect to="/home" text="HOME"/>
            <SectionToSelect to="/wines" text="WINES"/>
            <SectionToSelect to="/gallery" text="GALLERY"/>
            <SectionToSelect to="/contact" text="CONTACT"/>
            <div style={{ height: "35px", opacity: 0.7 }} className="border-secondary border-left mx-2">
            </div>
            <SectionToSelect hover={false}>
              <div className="d-flex">
                {Object.keys(langs).map(idiom =>
                  <div key={idiom} onClick={changeLocale} id={idiom}
                       className={"text-uppercase mx-2 mt-1 " + (idiom === locale ? "border-bottom border-secondary" : "")}>
                    {idiom}
                  </div>
                )}
              </div>
            </SectionToSelect>
          </div>

          <div className="row d-flex d-lg-none justify-content-between align-items-center py-3 px-4 px-md-5">
            <Link style={{ fontSize: "25px" }} to="/" className="text-white">
              <img onClick={this.closeMenu} height="40px" src={logo} alt=""/>
            </Link>
            <img onClick={this.toggleMenu} style={{ cursor: "pointer" }} width="25px" height="25px" src={menu} alt=""/>
          </div>
          <MoveDiv className="row flex-column d-flex d-lg-none align-items-center overflow-hidden"
                   pose={isVisibleMobileMenu ? 'open' : 'closed'}>
            <div className="col">
              <div className="row flex-column justify-content-center align-items-center">
                <SectionToSelect to="/home" text="HOME" onClick={this.closeMenu} hover={false}/>
                <SectionToSelect to="/wines" text="WINES" onClick={this.closeMenu} hover={false}/>
                <SectionToSelect to="/gallery" text="GALLERY" onClick={this.closeMenu} hover={false}/>
                <SectionToSelect to="/contact" text="CONTACT" onClick={this.closeMenu} hover={false}/>
                <SectionToSelect hover={false}>
                  <div className="d-flex">
                    {Object.keys(langs).map(idiom =>
                      <div key={idiom} onClick={changeLocale} id={idiom}
                           className={"text-uppercase ml-3 mr-2 " + (idiom === locale ? "border-bottom border-white" : "")}>
                        {idiom}
                      </div>
                    )}
                  </div>
                </SectionToSelect>
              </div>
            </div>

          </MoveDiv>
        </div>

      </React.Fragment>
    );
  }
}

export default traductorControl(localeConsControl(NavBar));
